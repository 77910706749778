import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../Image/Logo.png";
import { APIURL } from "./apiconfig";
function Login(props) {
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");
  const [strName, setstrName] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [isNewAccount, setisNewAccount] = useState(false);
  const [strOTP, setstrOTP] = useState("");
  const [isvalidateOTP, setisvalidateOTP] = useState(false);
  const [strMessage, setstrMessage] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(120);
  const [disblebtn, setdisblebtn] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => {
        clearTimeout(countdown);
        setdisblebtn(false);
      };
    }
  }, [timer]);

  const generateOTP = () => {
    const otpLength = 6;
    let newOTP = "";
    for (let i = 0; i < otpLength; i++) {
      newOTP += Math.floor(Math.random() * 10);
    }
    return newOTP;
  };
  const Verfy_User = () => {
    if (otp === strOTP && timer > 0) {
      const user = {
        _UserId: 0,
        _UserName: strEmail,
        _UserPassword: strPassword,
        _Name: strName,
        _Mobile: strMobile,
        _Email: strEmail,
        _UserStatus: 1,
      };
      axios
        .post(`${APIURL}InsUpd_User`, user)
        .then((res) => {
          if (res.data.status === "success") {
            alert("User Succesfully Created Please Try to Login Now");
            window.location.href = "/UserDashboard";
          } else {
            alert(res.data.Message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else setstrMessage("Please Enter valid OTP");
  };
  const check_emailexist = async () => {
    try {
      if (strName === "") alert("Please Enter Name");
      else if (strMobile === "") alert("Please Enter  Mobile Number");
      else if (strEmail === "") alert("Please Enter Email");
      else if (strPassword.length < 6)
        alert("Password atleast 6 letters Requeired");
      else {
        await axios
          .post(`${APIURL}VerifyEmail`, {
            _Email: strEmail,
          })
          .then((res) => {
            if (res.data.status === "success" && res.data.user.length > 0) {
              setstrMessage("This Email Already Exists");
            } else {
              Sent_OTP();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    } catch (err) {
      alert(err.message);
    }
  };
  const Sent_OTP = () => {
    try {
      let newOTP = generateOTP();
      setdisblebtn(true);
      axios
        .post(`${APIURL}SentOTP`, {
          NAME: strName,
          TO: strEmail,
          OTP: newOTP,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setstrMessage("OTP Successfully Sended Check Your E-mail");
            setTimer(120);
            setOTP(newOTP);
            setisvalidateOTP(true);
          } else {
            setstrMessage(res.data.Message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Login_user = () => {
    try {
      if (strUserName === "") alert("Please Enter user Name");
      else if (strPassword.length < 6) alert("please Enter valid Password");
      else {
        const user = {
          _UserName: strUserName,
          _UserPassword: strPassword,
        };
        axios
          .post(`${APIURL}Login_user`, user)
          .then((res) => {
            if (res.data.status === "success") {
              if (res.data.user.length === 0)
                alert("Please Enter Valid Username or Password");
              else {
                setstrMessage("Login Allowed only After 01-04-2024");
              }
            } else {
              alert(res.data.Message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    } catch (err) {
      alert(err.message);
    }
  };
  return (
    <div className="login-container">
      <div className="login-content" style={{ padding: "10px 20px" }}>
        <i
          className="fa-regular fa-circle-xmark close"
          onClick={(e) => props.Close()}
        ></i>
        <div className="login-img">
          <img src={logo} alt="Tails On Wheels" />
        </div>
        {!isNewAccount && !isvalidateOTP && (
          <>
            <h1 className="login-title">
              <span>Login Into Tails On Wheels</span> <sub>Let's Get Start</sub>
            </h1>
            <div className="login-input">
              <label>User Name</label>
              <input
                type="text"
                value={strUserName}
                onChange={(e) => setstrUserName(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Email"
              />
            </div>
            <div className="login-input">
              <label>Password</label>
              <input
                type="password"
                value={strPassword}
                onChange={(e) => setstrPassword(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Password"
              />
            </div>
            <button className="login-btn" onClick={(e) => Login_user()}>
              Login
            </button>
            <a href="#ResetPassword" className="login-reset">
              Forgot Password ?
            </a>
            <p className="ctr-acc">
              Create New Account
              <a href="##" onClick={(e) => setisNewAccount(!isNewAccount)}>
                Sign Up ?
              </a>
            </p>
          </>
        )}
        {isNewAccount && !isvalidateOTP && (
          <>
            <h1 className="login-title">
              <span>Create New Account</span>
            </h1>
            <p className="err-msg">{strMessage}</p>
            <div className="login-input">
              <label>
                Name<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={strName}
                onChange={(e) => setstrName(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Name"
              />
            </div>
            <div className="login-input">
              <label>
                Mobile Number<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={strMobile}
                onChange={(e) => setstrMobile(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Mobile Number"
              />
            </div>
            <div className="login-input">
              <label>
                Email<span className="danger">*</span>
              </label>
              <input
                type="text"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Email"
              />
            </div>
            <div className="login-input">
              <label>
                Password<span className="danger">*</span>
              </label>
              <input
                type="password"
                value={strPassword}
                onChange={(e) => setstrPassword(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter Password"
              />
            </div>
            <button
              className="login-btn"
              onClick={(e) => check_emailexist()}
              disabled={disblebtn}
            >
              Sent OTP
            </button>
            <p className="ctr-acc">
              Already Have an Account
              <a href="##" onClick={(e) => setisNewAccount(!isNewAccount)}>
                Sign In ?
              </a>
            </p>
          </>
        )}
        {isvalidateOTP && (
          <>
            <div className="login-input">
              <input
                type="number"
                value={strOTP}
                onChange={(e) => setstrOTP(e.target.value)}
                className="form-control"
                spellCheck={false}
                autoComplete="off"
                placeholder="Enter OTP"
                onClick={(e) => e.target.select()}
              />
            </div>
            <p className="otp-timer">
              OTP Expired in <strong> {timer}s</strong>
            </p>
            {timer > 20 && <p className="otp-msg">{strMessage}</p>}
            {timer > 0 && (
              <button className="login-btn" onClick={(e) => Verfy_User()}>
                Verify OTP
              </button>
            )}
            {timer <= 0 && (
              <button
                className="login-btn"
                onClick={(e) => Sent_OTP()}
                disabled={disblebtn}
              >
                Re-Sent OTP
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
