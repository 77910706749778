import React from "react";
import Menu from "./Menu";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <div className="Master-container">
      <Menu />
      <div className="termpol">
        <h1>Privacy Policy</h1>
        <h6>
          We Tails on Wheels is committed to protecting the privacy of our
          customers and their furry friends. This Privacy Policy describes the
          information we collect about you and your pet, how we use it, and the
          choices you have regarding your personal information.
        </h6>
        <strong> Information We Collect</strong> We collect two types of
        information: Customer Information: This includes your name, email
        address, phone number, physical address, and payment information. We
        collect this information when you book an appointment, contact us, or
        subscribe to our mailing list. Pet Information: This includes your pet's
        name, breed, age, gender, any medical conditions, and grooming
        preferences. We collect this information during the booking process and
        may gather additional details during the grooming appointment.
        <strong>How We Use Your Information</strong> We use your information for
        the following purposes: To provide and fulfill your mobile pet grooming
        appointments. To communicate with you about your appointments, including
        confirmations, reminders, and updates. To send you promotional offers
        and newsletters. To improve our services and
        website. To comply with legal requirements.
        <strong>Information Sharing and Disclosure</strong> We will not share
        your personal information with third parties except in the following
        situations: With your consent: We will share your information with third
        parties with your express consent. With Service Providers: We may share
        your information with third-party service providers who help us operate
        our business, such as payment processors, appointment scheduling tools,
        and marketing platforms. These service providers are obligated to keep
        your information confidential and use it only as instructed by us. For
        Legal Reasons: We may be required to disclose your information to comply
        with the law, a court order, or a subpoena.
        <strong>Data Security</strong> We take reasonable measures to protect
        your information from unauthorized access, disclosure, alteration, or
        destruction. We use secure servers, data encryption, and other security
        measures to safeguard your information. However, please be aware that no
        website or online transmission is completely secure. Your Choices You
        have the following choices regarding your personal information: You can
        access and update your information by contacting us. You can unsubscribe
        from our marketing emails by clicking the unsubscribe link in any email.
        You can request that we delete your information by contacting us.
        <strong>Changes to this Policy</strong> We may update this Privacy
        Policy from time to time. We will post any changes on this page and
        update the "Effective Date" at the top of the Policy. We encourage you
        to review this Privacy Policy periodically for the latest information on
        our privacy practices. <strong>Contact Us</strong> If you have any
        questions about this Privacy Policy, please contact us at
        tailsonwheels.cbe@gmail.com.
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
