import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomeSelect from "./CustomeSelect";
import { APIURL } from "./apiconfig";

function BookAppoinment(props) {
  const [strName, setstrName] = useState("");
  const [strMobile, setstrMobile] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strbreed, setstrbreed] = useState("");
  const [strAge, setstrAge] = useState("");
  const [dAmount, setdAmount] = useState((0).toFixed(2));
  const [iPackage, setiPackage] = useState(0);
  const [iLocId, setiLocId] = useState(0);
  const [strBlockNo, setstrBlockNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [dpFrom, setdpFrom] = useState("");
  const [dpTO, setdpTO] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [strRemark, setstrRemark] = useState("");
  const [isDog, setisDog] = useState(true);
  const [errName, seterrName] = useState("");
  const [errMobile, seterrMobile] = useState("");
  const [errEmail, seterrEmail] = useState("");
  const [errPincode, seterrPincode] = useState("");
  const [errPackage, seterrPackage] = useState("");
  const [strmessage, setstrmessage] = useState("");
  const [strdogName, setstrdogName] = useState("");
  const [arrEntPackage, setarrEntPackage] = useState([]);
  const [arrPackage, setarrPackage] = useState([]);
  const [arrLocation, setarrLocation] = useState([]);

  useEffect(() => {
    Load_Appoinment();
  }, []);
  const Load_Appoinment = () => {
    try {
      axios
        .get(`${APIURL}Load_Appoinment/`)
        .then((res) => {
          if (res.status === 200) {
            let EntPack = res.data.Appoinment[0].filter(
              (dt) => dt.PetTypeId === 1
            );
            setarrPackage(EntPack);
            setarrEntPackage(res.data.Appoinment[0]);
            setarrLocation(res.data.Appoinment[1]);
          } else {
            setstrmessage("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          setstrmessage(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const View_GroominPackage = (pack) => {
    setiPackage(pack.PackgId);
    setdAmount(pack.Amount.toFixed(2));
  };
  const Show_GroominPackage = async () => {
    await setiPackage(0);
    await setdAmount((0).toFixed(2));
    let dogpack = !isDog;
    let PetType = dogpack ? 1 : 2;
    await setisDog(dogpack);
    let EntPack = arrEntPackage.filter((dt) => dt.PetTypeId === PetType);
    await setarrPackage(EntPack);
  };
  const View_Location = (location) => {
    setiLocId(location.LocId);
    setdpFrom(location.dpFrom);
    setdpTO(location.dpTo);
  };
  const Clear_Appoinmnet = () => {
    setstrName("");
    setstrMobile("");
    setstrEmail("");
    setstrbreed("");
    setstrAge("");
    setstrbreed("");
    setdAmount((0).toFixed(2));
    setiPackage(0);
    setisDog(true);
    setstrmessage("");
  };
  const Close_Popup = () => {
    setTimeout(() => {
      Send_SuccessEmail();
    }, 2000);
  };
  const Clear_message = (type, msg) => {
    switch (type) {
      case "strName":
        seterrName(msg);
        seterrMobile("");
        seterrPincode("");
        seterrPackage("");
        setstrmessage("");
        seterrEmail("");
        break;
      case "strMobile":
        seterrName("");
        seterrMobile(msg);
        seterrPincode("");
        seterrPackage("");
        setstrmessage("");
        seterrEmail("");
        break;
      case "strEmail":
        seterrEmail(msg);
        seterrName("");
        seterrMobile("");
        seterrPincode("");
        seterrPackage("");
        setstrmessage("");
        break;
      case "strPincode":
        seterrName("");
        seterrMobile("");
        seterrPincode(msg);
        seterrPackage("");
        setstrmessage("");
        seterrEmail("");
        break;
      case "iPackage":
        seterrName("");
        seterrMobile("");
        seterrPincode("");
        seterrPackage(msg);
        setstrmessage("");
        seterrEmail("");
        break;
      default:
        seterrName("");
        seterrMobile("");
        seterrPincode("");
        seterrPackage("");
        seterrEmail("");
        setstrmessage(msg);
        break;
    }
  };
  const InsUpd_Appoinment = () => {
    try {
      if (strName === "") Clear_message("strName", "Please Enter name");
      else if (strPincode === "")
        Clear_message("strPincode", "Please Enter Pincode");
      else if (strMobile === "")
        Clear_message("strMobile", "Please Enter Mobile Number");
      else if (strMobile.length < 10)
        Clear_message("strMobile", "Please Enter Valid Mobile Number");
      else if (strEmail === "") Clear_message("strEmail", "Please Enter Email");
      else if (iPackage === 0)
        Clear_message("iPackage", "Please Select Package");
      else {
        const objApp = {
          _AppoinmentId: 0,
          _Customer: strName,
          _Mobile: strMobile,
          _Email: strEmail,
          _Age: strAge,
          _breed: strbreed,
          _dogName: strdogName,
          _Amount: dAmount,
          _Package: iPackage,
          _Pettype: isDog ? 1 : 2,
          _BlockNo: strBlockNo,
          _Street: strStreet,
          _Area: strArea,
          _Pincode: strPincode,
          _Remark: strRemark,
        };
        axios
          .post(`${APIURL}InsUpd_appoinment`, objApp)
          .then((res) => {
            if (res.data.status === "success") {
              setstrmessage(res.data.Message);
              Close_Popup();
            } else {
              Clear_message(res.data.Message);
            }
          })
          .catch((err) => {
            Clear_message(err.message);
          });
      }
    } catch (err) {
      Clear_message(err.message);
    }
  };
  const Send_SuccessEmail = () => {
    try {
      axios
        .post(`${APIURL}Sent_AppoinmetRegistration`, {
          NAME: strName,
          TO: strEmail,
          FROMTO: dpFrom,
          TODATE: dpTO,
        })
        .then((res) => {
          if (res.data.status === "success") {
          } else {
            console.log(res.data.Message);
          }
          Clear_Appoinmnet();
          props.Close();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div className="apmt-container">
      <div className="apmt-content">
        <i
          className="fa-regular fa-circle-xmark close"
          onClick={(e) => props.Close()}
        ></i>
        <h1 className="Apmt-title">Book Appoinment</h1>
        <div className="Apmt-input">
          <div className="demoinput">
            <label>
              Name<span className="danger">*</span>
            </label>
            <div className="input">
              <i className="fa-regular fa-user"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter name"
                value={strName}
                onChange={(e) => setstrName(e.target.value)}
              />
            </div>
            <span className="err-msg">{errName}</span>
          </div>
          <div className="demoinput">
            <label>Door / Block No</label>
            <div className="input">
              <i className="fa-solid fa-location-dot"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Door No"
                value={strBlockNo}
                onChange={(e) => setstrBlockNo(e.target.value)}
              />
            </div>
          </div>
          <div className="demoinput">
            <label>Street</label>
            <div className="input">
              <i className="fa-solid fa-location-dot"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter street"
                value={strStreet}
                onChange={(e) => setstrStreet(e.target.value)}
              />
            </div>
          </div>
          <div className="demoinput">
            <label>
              Pincode<span className="danger">*</span>
            </label>
            <div className="input">
              <i className="fa-solid fa-location-dot"></i>
              <input
                type="number"
                className="form-control"
                placeholder="enter Pincode"
                value={strPincode}
                onChange={(e) => setstrPincode(e.target.value)}
              />
            </div>
            <span className="err-msg">{errPincode}</span>
          </div>
          <div className="demoinput">
            <label>
              Mobile<span className="danger">*</span>
            </label>
            <div className="input">
              <i className="fa-solid fa-phone"></i>
              <input
                type="number"
                className="form-control"
                placeholder="enter mobile number"
                value={strMobile}
                onChange={(e) => setstrMobile(e.target.value)}
              />
            </div>
            <span className="err-msg">{errMobile}</span>
          </div>
          <div className="demoinput">
            <label>
              Email<span className="danger">*</span>
            </label>
            <div className="input">
              <i className="fa-regular fa-envelope"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter email"
                value={strEmail}
                onChange={(e) => setstrEmail(e.target.value)}
              />
            </div>
            <span className="err-msg">{errEmail}</span>
          </div>
          <div className="demoinput">
            <label>
              Area<span className="danger">*</span>
            </label>
            <CustomeSelect
              EntData={arrLocation}
              name="Location"
              val="LocId"
              value={iLocId}
              value_onchange={(e) => View_Location(e)}
            />
            {dpFrom.length > 0 && (
              <span className="mt-2 mb-2">
                📅 Dates: {dpFrom} to {dpTO}
                <br />
                📍 Location: Within 2.5 km radius
              </span>
            )}
          </div>
          <div className="check-input">
            <input
              type="checkbox"
              className="form-check"
              id="isdog"
              checked={isDog}
              onChange={(e) => {
                Show_GroominPackage(1);
              }}
            />
            <label htmlFor="isdog">
              Dog<span className="danger">*</span>
            </label>
            <input
              type="checkbox"
              id="iscat"
              className="form-check"
              checked={!isDog}
              onChange={(e) => {
                Show_GroominPackage(2);
              }}
            />
            <label htmlFor="iscat">
              Cat<span className="danger">*</span>
            </label>
          </div>
          <div className="demoinput">
            <label>
              Grooming Package<span className="danger">*</span>
            </label>
            <CustomeSelect
              EntData={arrPackage}
              name="PackageName"
              val="PackgId"
              value={iPackage}
              value_onchange={(e) => View_GroominPackage(e)}
            />
            <span className="err-msg">{errPackage}</span>
          </div>
          <div className="demoinput">
            <label>Pet Name</label>
            <div className="input">
              <i className="fa-solid fa-paw"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter name"
                value={strdogName}
                onChange={(e) => setstrdogName(e.target.value)}
              />
            </div>
          </div>
          <div className="demoinput">
            <label>Breed Name</label>
            <div className="input">
              <i className="fa-solid fa-paw"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter breed name"
                value={strbreed}
                onChange={(e) => setstrbreed(e.target.value)}
              />
            </div>
          </div>
          <div className="demoinput">
            <label>Pet Age</label>
            <div className="input">
              <i className="fa-solid fa-calendar"></i>
              <input
                type="text"
                className="form-control"
                placeholder="enter dog age"
                value={strAge}
                onChange={(e) => setstrAge(e.target.value)}
              />
            </div>
          </div>
          <div className="demoinput">
            <label>Total Payable Amount</label>
            <div className="input">
              <i className="fa-solid fa-indian-rupee-sign"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Total Amount"
                value={dAmount}
                disabled
              />
            </div>
          </div>
          <div className="demoinput">
            <label>
              Message<span className="danger">*</span>
            </label>
            <div className="input">
              <i className="fa-solid fa-message"></i>
              <textarea
                type="text"
                className="form-control"
                placeholder="Leave Your Message"
                value={strRemark}
                onChange={(e) => setstrRemark(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className="s-btn" onClick={(e) => InsUpd_Appoinment()}>
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default BookAppoinment;
