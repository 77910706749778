import React from "react";
import $ from "jquery";
import Logo from "../Image/Logo.png";
function Menu() {
  const menuclick = (index) => {
    $(`.nav-menu .menu-item:eq(${index})`)
      .toggleClass("show")
      .siblings()
      .removeClass("show");
  };
  return (
    <>
      <div
        id="btn-grid"
        onClick={(e) => {
          $(".nav-menu").toggleClass("active");
          $("#btn-grid").toggleClass("active");
        }}
      >
        <i className="bx bxs-grid-alt"></i>
      </div>
      <section className="nav-menu">
        <a href="/#Home" className="logo-container">
          <img src={Logo} alt="Tails on Wheels" />
        </a>
        <ul>
          <li className="menu-item show">
            <a href="/#Home" onClick={(e) => menuclick(0)}>
              Home
            </a>
            <hr />
          </li>
          <li className="menu-item">
            <a href="##" onClick={(e) => menuclick(1)}>
              <span> Services</span>
              <i className="fa-solid fa-sort-down"></i>
            </a>
            <hr />
            <ul>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Grooming</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Gog Training</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Taxi and Cab</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Relocation</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Funeral</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Vaccines</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#whyus")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Pet Ambulance</h3>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <a href="##" onClick={(e) => menuclick(2)}>
              <span>Pricing</span> <i className="fa-solid fa-sort-down"></i>
            </a>
            <hr />
            <ul>
              <li onClick={(e) => (window.location.href = "/#Pricing")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Silver</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#Pricing")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Gold</h3>
              </li>
              <li onClick={(e) => (window.location.href = "/#Pricing")}>
                <i className="fa-solid fa-paw"></i>

                <h3>Platinum</h3>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <a href="##" onClick={(e) => menuclick(3)}>
              <span>Career</span> <i className="fa-solid fa-sort-down"></i>
            </a>
            <hr />
            <ul>
              <li>
                <i className="fa-solid fa-paw"></i>

                <h3>Dog Trainer</h3>
              </li>
              <li>
                <i className="fa-solid fa-paw"></i>

                <h3>Care Taker</h3>
              </li>
              <li>
                <i className="fa-solid fa-paw"></i>

                <h3>Groomer</h3>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <a href="##" onClick={(e) => menuclick(4)}>
              Contact
            </a>
            <hr />
          </li>
        </ul>
      </section>
    </>
  );
}
export default Menu;
