import React from "react";

function DateSelection(props) {
  return (
    <div className="date-popup">
      {props.isfrom && (
        <input
          type="date"
          className="form-control"
          value={props.fromdate}
          onChange={(e) => props.Close("From", e.target.value)}
        />
      )}
      {!props.isfrom && (
        <input
          type="date"
          className="form-control"
          value={props.todate}
          onChange={(e) => props.Close("To", e.target.value)}
        />
      )}
    </div>
  );
}

export default DateSelection;
