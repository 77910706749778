import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import Subscription from "./Subscription";
import DogCat from "../Image/DogCat.png";
import bath from "../Image/bath.jpg";
import hairdry from "../Image/hairdry.jpg";
import NailCut from "../Image/NailCut.png";
import HairCut from "../Image/HairCut.jpg";
import Funeral from "../Image/Funeral.jpg";
import pettaxi from "../Image/petaxi.jpg";
import petlocation from "../Image/petlocation.jpg";
import DoorstepService from "../Image/DoorstepService.jpg";
//import Review from "./Review";
import $ from "jquery";
import Headtpaw from "../Image/Between/Headtpaw.png";
import catrest from "../Image/Between/catrest.png";
import Path from "../Image/Between/Path.png";
import Login from "./Login";
import BookAppoinment from "./BookAppoinment";
import ImageComponent from "./ImageLoading";
function LandingPage() {
  const [isAppoinment, setisAppoinment] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  useEffect(() => {
    let i = 0;
    setInterval((e) => {
      $(`.tw-hdesc  .h-subtitle:eq(${i})`)
        .addClass("active")
        .siblings()
        .removeClass("active");
      i++;
      if (i === 3) i = 0;
    }, 10000);
  }, []);
  return (
    <div className="Master-container">
      <Menu />
      <section id="Home">
        <div className="header_Container">
          <div className="tw-htext">
            <h1>
              <p>Welcome to Tails on Wheels !</p>
              <small>Coimbatore's</small>
              <br />
              <strong className="rank">No 1</strong>
              <br />
              <strong>Van Pet Grooming</strong>
            </h1>
            <div className="tw-btn">
              <button onClick={(e) => (window.location.href = "#Pricing")}>
                Book Appoinment <i className="fa-regular fa-clock"></i>
              </button>
            </div>
            <div className="tw-hdesc">
              <div className="h-subtitle active">
                <span className="material-symbols-outlined">pet_supplies</span>
                <p>
                  A Dog Grooming studio at your doorstep, where we bring the
                  <br />
                  salon experience right to your doorstep.
                </p>
              </div>
              <div className="h-subtitle">
                <span>
                  <i className="fa-solid fa-paw"></i>
                </span>
                <p>
                  Pamper your beloved furry friends with our expert grooming
                  services,
                </p>
              </div>
              <div className="h-subtitle">
                <span>
                  <i className="fa-solid fa-heart"></i>
                </span>
                <p>
                  We treat your pets with love and care. <br />
                  Your pets' well-being is our top priority!
                </p>
              </div>
            </div>
          </div>
          <div className="tw-htimage">
            <ImageComponent
              src={Headtpaw}
              alt="Tails On Wheels"
              className="htimage"
            />
            <ImageComponent
              src={DogCat}
              alt={"Tails On Wheels"}
              className="htimage"
            />
          </div>
        </div>
        <span id="paw1">
          <i className="fa-solid fa-paw"></i>
        </span>
        <span id="paw2">
          <i className="fa-solid fa-paw"></i>
        </span>
        <span id="paw3">
          <i className="fa-solid fa-paw"></i>
        </span>
        <span id="paw4">
          <i className="fa-solid fa-paw"></i>
        </span>
        <span id="paw5">
          <i className="fa-solid fa-paw"></i>
        </span>
        <span id="paw6">
          <i className="fa-solid fa-paw"></i>
        </span>
      </section>
      <Subscription BookNow={(e) => setisAppoinment(!isAppoinment)} />
      <section className="serv-con" id="Services">
        <div className="serv-conhead">
          <h1>What We Doing ?</h1>
        </div>
        <ul className="services">
          <li>
            <h1> Doorstep Service</h1>
            <div className="image-cropper">
              <ImageComponent src={DoorstepService} alt="Tails On Wheels" />
            </div>
            <p>
              Book online or by phone for flexible grooming appointments. Our
              groomer comes to your doorstep, well-equipped and on time,
              catering to your pet's needs
            </p>
          </li>
          <li>
            <h1> Complete bath station</h1>
            <div className="image-cropper">
              <ImageComponent src={bath} alt="Tails On Wheels" />
            </div>
            <p>
              Indulge your pet with a lavish bath from Tails on Wheels. We use
              premium products for a soft, shiny coat and a stress-free grooming
              experience
            </p>
          </li>
          <li>
            <h1>Blow Drying</h1>
            <div className="image-cropper">
              <ImageComponent src={hairdry} alt="Tails On Wheels" />
            </div>
            <p>
              We Treat your pet to a stress-free blow dry at Paws & Shine. Our
              professional service includes safe deshedding treatments and
              through brushing for a clean, tangle-free coat
            </p>
          </li>
          <li>
            <h1>Nail trimming and scrapping</h1>
            <div className="image-cropper">
              <ImageComponent src={NailCut} alt="Tails On Wheels" />
            </div>
            <p>
              Ensure your pet's well-being with our nail trimming service. Our
              experienced groomers will carefully trim nails to the right
              length, promoting comfort and preventing health issues.
            </p>
          </li>
          <li>
            <h1>Hair cut and Styling</h1>
            <div className="image-cropper">
              <ImageComponent src={HairCut} alt="Tails On Wheels" />
            </div>
            <p>
              We prioritize pets' well-being with a range of grooming services,
              from baths to creative styling. Our certified groomers are
              dedicated to giving your furry friend a pampering, stress-free
              experience, ensuring they look and feel their best
            </p>
          </li>
          <li>
            <h1> Funeral </h1>
            <div className="image-cropper">
              <ImageComponent src={Funeral} alt="Tails On Wheels" />
            </div>
            <p>
              Tails on Wheels provides compassionate and affordable pet funeral
              services for a dignified farewell. Our commitment to personalized
              solutions acknowledges the profound significance of pets in the
              family.
            </p>
          </li>
          <li>
            <h1> Pet taxi</h1>
            <div className="image-cropper">
              <ImageComponent src={pettaxi} alt="Tails On Wheels" />
            </div>
            <p>
              We prioritize your pet's well-being with safe and stress-free
              transportation. Our caring drivers treat your furry companion like
              family, offering flexible scheduling and diverse services
            </p>
          </li>
          <li>
            <h1> Pet relocation</h1>
            <div className="image-cropper">
              <ImageComponent src={petlocation} alt="Tails On Wheels" />
            </div>
            <p>
              Tails on Wheels ensures a stress-free pet relocation experience,
              prioritizing your furry companion's safety and comfort. Our expert
              team guarantees seamless moves, whether across the city or beyond
            </p>
          </li>
        </ul>
      </section>
      <section className="whyus" id="whyus">
        <ImageComponent src={catrest} alt="PET Grooming" className="cat-img" />
        <h1>
          Why <strong>Us?</strong>
        </h1>
        <p className="desc">
          At Tails on Wheels, we take pride in offering a convenient and
          stress-free grooming experience for your beloved pets. Our mobile
          grooming studio in Van is designed to cater to your furry friend's
          needs with the utmost care and professionalism. Here's a glimpse into
          how we work:
        </p>
        <div className="whyus-content">
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Mobile Convenience</h5>
              <span>
                Our fully equipped grooming studio on wheels comes to your
                doorstep. No need to stress your pet with a car ride or wait in
                line at a traditional groomer.
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Personalized Service</h5>
              <span>
                We provide one-on-one attention to your pet. Our skilled
                groomers focus solely on your pet during their grooming session,
                ensuring they receive the individualized care they deserve
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Stress-Free Environment</h5>
              <span>
                Your pet enjoys a calm and comfortable environment inside our
                studio, away from the hustle and bustle of a busy salon. We
                create a soothing atmosphere to keep your pet at ease
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Skilled Professionals</h5>
              <span>
                Our team of experienced groomers is dedicated to the well-being
                of your pet. They are trained to handle various breeds and
                ensure that your pet looks and feels their best
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Comprehensive Services</h5>
              <span>
                From baths, haircuts, nail trimming, ear cleaning, and more, we
                offer a full range of grooming services to meet your pet's
                specific needs
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>High-Quality Products</h5>
              <span>
                We use top-quality grooming products that are gentle on your
                pet's skin and coat, ensuring they leave our studio feeling
                fresh and looking fantastic
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Hygiene and Safety</h5>
              <span>
                We maintain strict hygiene and safety standards in our studio,
                providing a clean and secure environment for your pet's grooming
                session
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Appointment Flexibility</h5>
              <span>
                We work around your schedule, making it convenient for you to
                book a grooming session that suits your needs
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Transparent Pricing</h5>
              <span>
                Our pricing is transparent, with no hidden fees. You'll know
                exactly what to expect, and we offer competitive rates for our
                mobile grooming services
              </span>
            </div>
          </div>
          <div className="whyuscont">
            <i className="fa-solid fa-paw whysimg"></i>
            <div className="whyus-cart">
              <h5>Happy and Healthy Pets</h5>
              <span>
                Our goal is to leave your pet happy, healthy, and looking their
                best. We work diligently to ensure your pet's grooming
                experience is a positive one
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <p className="whyus-footer">
              Tails on Wheels is your trusted partner in ensuring your pet's
              well-being and appearance. Experience the difference of mobile
              grooming in Van with us – a stress-free, convenient, and
              personalized approach to pet grooming. Book your pet's next
              grooming appointment with us today
            </p>
          </div>
          <div className="col-sm-12 col-md-4">
            <img src={Path} alt="PET Grooming" className="path-cat" />
          </div>
        </div>
      </section>
      {/* <Review /> */}
      <Footer />
      {isAppoinment && (
        <BookAppoinment
          Close={(e) => {
            setisAppoinment(!isAppoinment);
            window.location.href = "#Home";
          }}
        />
      )}
      {isLogin && <Login Close={(e) => setisLogin(!isLogin)} />}
    </div>
  );
}

export default LandingPage;
