import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import DateSelection from "./DateSelection";
import axios from "axios";
import { APIURL } from "./apiconfig";
function Dashboard() {
  const [dpfromdate, setdpfromdate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dptodate, setdptodate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isdate, setisdate] = useState(false);
  const [isfrom, setisfrom] = useState(true);
  const [isNewExpense, setisNewExpense] = useState(false);
  const [dRevenue, setdRevenue] = useState(0);
  const [dExpense, setdExpense] = useState(0);
  const [iUser, setiUser] = useState(0);
  const [iAppoinments, setiAppoinments] = useState(0);

  const [dExpensAmt, setdExpensAmt] = useState(0);
  const [strExpence, setstrExpence] = useState("");
  const [dpexpense, setdpexpense] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [iOrderId, setiOrderId] = useState(0);
  const [OrderNo, setOrderNo] = useState("");
  const [strName, setstrName] = useState("");
  const [strEmail, setstrEmail] = useState("");
  const [strblockNo, setstrblockNo] = useState("");
  const [strStreet, setstrStreet] = useState("");
  const [strArea, setstrArea] = useState("");
  const [strPincode, setstrPincode] = useState("");
  const [strPhone, setstrPhone] = useState("");
  const [strDate, setstrDate] = useState("");
  const [strTime, setstrTime] = useState("");
  const [strpettype, setstrpettype] = useState("");
  const [strpetage, setstrpetage] = useState("");
  const [strbreed, setstrbreed] = useState("");
  const [strpackage, setstrpackage] = useState("");
  const [dprice, setdprice] = useState(0);
  const [iExpenceId, setiExpenceId] = useState(0);
  const [strDescription, setstrDescription] = useState("");
  const [isAppoinment, setisAppoinment] = useState(false);
  const [arrNewOrders, setarrNewOrders] = useState([]);
  const [arrEntOrders, setarrEntOrders] = useState([]);
  const [arrEntExpence, setarrEntExpence] = useState([]);
  const [arrEntUser, setarrEntUser] = useState([]);
  const [arrShowcard, setarrShowcard] = useState([true, false, false, false]);
  useEffect(() => {
    View_Summary();
    View_BookedAppoinments();
  }, []);
  const View_Summary = () => {
    try {
      axios
        .get(`${APIURL}View_Summary`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data[0].TotalRevenue !== undefined) {
              setdRevenue(res.data[0].TotalRevenue);
              setdExpense(res.data[0].TotalExpence);
              setiUser(res.data[0].TotalUsers);
              setiAppoinments(res.data[0].TotalAppoinments);
            }
          } else {
            alert("Error Getting Summary");
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const View_BookedAppoinments = () => {
    try {
      axios
        .get(`${APIURL}View_BookedAppoinments`)
        .then((res) => {
          if (res.status === 200) {
            setarrEntOrders(res.data);
          } else {
            alert("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Load_Appoinments = () => {
    try {
      axios
        .get(`${APIURL}View_Appoinments`)
        .then((res) => {
          if (res.status === 200) {
            setarrNewOrders(res.data);
          } else {
            alert("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Load_Expences = () => {
    try {
      axios
        .get(`${APIURL}View_Expences`)
        .then((res) => {
          if (res.status === 200) {
            setarrEntExpence(res.data);
          } else {
            alert("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Load_Users = () => {
    try {
      axios
        .get(`${APIURL}View_User`)
        .then((res) => {
          if (res.status === 200) {
            setarrEntUser(res.data);
          } else {
            alert("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const date_onchange = (type, date) => {
    setisdate(!isdate);
    setisfrom(true);
    switch (type) {
      case "From":
        setdpfromdate(date);
        break;
      default:
        setdptodate(date);
        break;
    }
  };
  const numtoamount = (number) => {
    number = number > 0 ? number : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number.toFixed(2));
  };
  const show_card = (tabindex) => {
    let EntCard = arrShowcard;
    EntCard = EntCard.map((dt, Index) => {
      if (tabindex === Index) dt = true;
      if (tabindex !== Index) dt = false;
      return dt;
    });
    setarrShowcard(EntCard);
    if (tabindex === 2) Load_Appoinments();
    if (tabindex === 3) Load_Users();
    if (tabindex === 1) Load_Expences();
  };
  const View_Order = (order) => {
    setisAppoinment(true);
    setiOrderId(order.AppoinmentId);
    setOrderNo(order.OrderNo);
    setstrName(order.Customer);
    setstrblockNo(order.BlockNo);
    setstrStreet(order.Street);
    setstrArea(order.Area);
    setstrPincode(order.Pincode);
    setstrPhone(order.Mobile);
    setstrDate(order.Transdate);
    setstrTime(order.Transtime);
    setstrpettype(order.Pettype);
    setstrpetage(order.Age);
    setstrbreed(order.breed);
    setstrpackage(order.PackageName);
    setdprice(order.Price);
    setstrDescription(order.Remark);
    setstrEmail(order.Email);
  };
  const Accept_order = (isaccept) => {
    try {
      const objparems = {
        Name: strName,
        OrderNo: "#Order-" + OrderNo,
        OrderDate: strDate,
        Package_Name: strpackage,
        Price: dprice,
        to: strEmail,
        orderid: iOrderId,
        isaccept: isaccept,
        Reason: "",
      };
      axios
        .post(`${APIURL}AcceptRejectOrder`, objparems)
        .then((res) => {
          if (res.data.status === "success") {
            setisAppoinment(!isAppoinment);
            alert(res.data.Message);
            Load_Appoinments();
          } else alert(res.data.Message);
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Delete_User = (UserId) => {
    try {
      axios
        .post(`${APIURL}Delete_user/${UserId.UserId}`)
        .then((res) => {
          if (res.data.status === "success") {
            alert("User Succesfully Deleted");
            Load_Users();
            View_Summary();
          } else {
            alert(res.data.Message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const Clear_Expence = () => {
    setiExpenceId(0);
    setstrExpence("");
    setdpexpense(new Date().toISOString().split("T")[0]);
    setdExpensAmt(0);
    setstrDescription("");
  };
  const Add_Expence = () => {
    try {
      if (strExpence === "") alert("Please Enter Valid Expence");
      else if (dExpensAmt === 0) alert("Please Enter Amount");
      else {
        const Expence = {
          _ExpenceId: iExpenceId,
          _Expence: strExpence,
          _TransDate: dpexpense,
          _Amount: Number(dExpensAmt),
          _Description: strDescription,
        };
        axios
          .post(`${APIURL}InsUpd_Expence`, Expence)
          .then((res) => {
            if (res.status === 200) {
              Clear_Expence();
              Load_Expences();
              setisNewExpense(!isNewExpense);
              alert("Expence Succesfully Updated");
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    } catch (err) {
      alert(err.message);
    }
  };
  const Close_Expense = () => {
    setisNewExpense(!isNewExpense);
  };
  return (
    <div className="tw-container">
      <Menu />
      <section className="twdash-header">
        <h1>DashBoard</h1>
        <div className="dash-header-date">
          <div className="dt-sec">
            <label
              onClick={(e) => {
                setisdate(true);
                setisfrom(true);
              }}
            >
              {dpfromdate}
            </label>
            -
            <label
              onClick={(e) => {
                setisdate(true);
                setisfrom(false);
              }}
            >
              {dptodate}
            </label>
            <i className="fa-solid fa-calendar"></i>
          </div>
          <button className="View">View</button>
          <button className="Download">Download</button>
          {isdate && (
            <DateSelection
              Close={(type, date) => date_onchange(type, date)}
              fromdate={dpfromdate}
              todate={dptodate}
              isfrom={isfrom}
            />
          )}
        </div>
      </section>
      <section className="tw-hwrapper">
        <div className="tw-card" onClick={(e) => show_card(0)}>
          <i className="bx bx-user"></i>
          <div>
            <p>{numtoamount(dRevenue)}</p>
            <h2>Total Revenue</h2>
          </div>
        </div>
        <div className="tw-card" onClick={(e) => show_card(1)}>
          <i className="bx bx-user"></i>
          <div>
            <p>{numtoamount(dExpense)}</p>
            <h2>Total Expense</h2>
          </div>
        </div>
        <div className="tw-card" onClick={(e) => show_card(2)}>
          <i className="bx bx-calendar-check"></i>
          <div>
            <p>{iAppoinments}</p>
            <h2>Appoinments</h2>
          </div>
        </div>
        <div className="tw-card" onClick={(e) => show_card(3)}>
          <i className="bx bx-user"></i>
          <div>
            <p>{iUser}</p>
            <h2>Users</h2>
          </div>
        </div>
      </section>
      {arrShowcard[0] && (
        <section className="tw-bwrapper">
          <h2>Order History</h2>
          <table className="ap-list">
            <thead className="info">
              <tr>
                <th>
                  ID <i className="fa-solid fa-retweet"></i>
                </th>
                <th>
                  PACKAGE <i className="fa-solid fa-retweet"></i>
                </th>
                <th>
                  CUSTOMER NAME <i className="fa-solid fa-retweet"></i>
                </th>
                <th>
                  PAYMENT INFO <i className="fa-solid fa-retweet"></i>
                </th>
                <th>
                  PRICE <i className="fa-solid fa-retweet"></i>
                </th>
                <th>
                  STATUS <i className="fa-solid fa-retweet"></i>
                </th>
              </tr>
            </thead>
            <tbody className="info">
              {arrEntOrders.map((dt, Index) => (
                <tr key={Index}>
                  <td id="tdf">#Order-{dt.OrderNo}</td>
                  <td>{dt.PackageName}</td>
                  <td>{dt.Customer}</td>
                  <td>{dt.PayMode}</td>
                  <td id="amount">{numtoamount(dt.Price)}</td>
                  <td id={dt.Status} className="btn-status">
                    <span>{dt.Status}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
      {arrShowcard[1] && (
        <section className="tw-bwrapper">
          <h2 className="tab-header">
            EXPENSE
            <button onClick={(e) => setisNewExpense(!isNewExpense)}>
              <i className="fa-solid fa-plus"></i> <span>Add Expense</span>
            </button>
          </h2>
          <div className="row" style={{ width: "99%", margin: "0 auto" }}>
            <div
              className={
                isNewExpense ? "col-sm-12 col-md-9" : "col-sm-12 col-md-12"
              }
            >
              <table className="ap-list">
                <thead className="info">
                  <tr>
                    <th>ID</th>
                    <th>EXPENSE</th>
                    <th>DATE</th>
                    <th>AMOUNT</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="info">
                  {arrEntExpence.map((dt, Index) => (
                    <tr>
                      <td id="tdf">#Expense-{dt.ExpenceId}</td>
                      <td>{dt.Expence}</td>
                      <td>{dt.Transdate}</td>
                      <td id="amount">{numtoamount(dt.Amount)}</td>
                      <td>{dt.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isNewExpense && (
              <div className="col-sm-12 col-md-3">
                <div className="demoinput">
                  <label>Expence</label>
                  <div className="input">
                    <input
                      type="text"
                      className="form-control"
                      value={strExpence}
                      onChange={(e) => setstrExpence(e.target.value)}
                      spellCheck="false"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="demoinput">
                  <label>Amount</label>
                  <div className="input">
                    <i className="fa-solid fa-indian-rupee-sign"></i>
                    <input
                      type="number"
                      className="form-control"
                      value={dExpensAmt}
                      onChange={(e) => setdExpensAmt(e.target.value)}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                </div>
                <div className="demoinput">
                  <label>Description</label>
                  <div className="input">
                    <textarea
                      className="form-control"
                      value={strDescription}
                      onChange={(e) => setstrDescription(e.target.value)}
                      spellCheck="false"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="demoinput">
                  <label>Date</label>
                  <div className="input">
                    <input
                      type="date"
                      className="form-control"
                      value={dpexpense}
                      onChange={(e) => setdpexpense(e.target.value)}
                    />
                  </div>
                </div>
                <div className="appbtn">
                  <button className="s-btn" onClick={(e) => Add_Expence()}>
                    Add Expense
                  </button>
                  <button className="s-btn" onClick={(e) => Close_Expense()}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      {arrShowcard[2] && (
        <section className="tw-bwrapper">
          <h2>{isAppoinment ? `#Order-${iOrderId}` : "New Orders"}</h2>
          {!isAppoinment && (
            <table className="ap-list">
              <thead className="info">
                <tr>
                  <th>NAME</th>
                  <th>LOCATION</th>
                  <th>DATE</th>
                  <th>TIME</th>
                  <th>PACKAGE</th>
                  <th>PET</th>
                  <th>BREED</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody className="info">
                {arrNewOrders.map((order, Index) => (
                  <tr>
                    <td id="tdf">{order.Customer}</td>
                    <td>{order.Area}</td>
                    <td>{order.Transdate}</td>
                    <td>{order.Transtime}</td>
                    <td>{order.PackageName}</td>
                    <td>{order.Pettype}</td>
                    <td>{order.breed}</td>
                    <td className="btn-action">
                      <button onClick={(e) => View_Order(order)}>View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isAppoinment && (
            <div className="appoinmentinfo">
              <div className="app-cart">
                <h4>Address</h4>
                <p>
                  <span>Name:</span>
                  <span>{strName}</span>
                </p>
                <p>
                  <span>Block No:</span>
                  <span>{strblockNo}</span>
                </p>
                <p>
                  <span>Street:</span> <span>{strStreet}</span>
                </p>
                <p>
                  <span>Area:</span> <span>{strArea}</span>
                </p>
                <p>
                  <span>Pincode:</span> <span>{strPincode}</span>
                </p>
                <p>
                  <span>Phone:</span> <span>{strPhone}</span>
                </p>
              </div>
              <div className="app-cart">
                <h4>Pet Detailes</h4>
                <p>
                  <span>Type:</span>
                  <span>{strpettype}</span>
                </p>
                <p>
                  <span>Age:</span> <span>{strpetage}</span>
                </p>
                <p>
                  <span>Breed:</span> <span>{strbreed}</span>
                </p>
                <p>Description</p>
                <p>{strDescription}</p>
              </div>
              <div className="app-cart">
                <h4>Appoinment Info</h4>
                <p>
                  <span>Id:</span>
                  <span>#Order-{OrderNo}</span>
                </p>
                <p>
                  <span>Date:</span>
                  <span>{strDate}</span>
                </p>
                <p>
                  <span>Time:</span> <span>{strTime}</span>
                </p>
                <p>
                  <span>Package:</span> <span>{strpackage}</span>
                </p>
                <p>
                  <span>Price:</span>
                  <span id="amount">{numtoamount(dprice)}</span>
                </p>
              </div>
            </div>
          )}
          {isAppoinment && (
            <div className="appbtn">
              <button onClick={(e) => Accept_order(3)}>Accept</button>
              <button onClick={(e) => Accept_order(0)}>Reject</button>
              <button onClick={(e) => setisAppoinment(!isAppoinment)}>
                Close
              </button>
            </div>
          )}
        </section>
      )}
      {arrShowcard[3] && (
        <section className="tw-bwrapper">
          <h2>User List</h2>
          <table className="ap-list">
            <thead className="info">
              <tr>
                <th>NAME</th>
                <th>MOBILE</th>
                <th>EMAIL</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody className="info">
              {arrEntUser.map((user, Index) => (
                <tr>
                  <td id="tdf">{user.CustName}</td>
                  <td>{user.Mobile}</td>
                  <td>{user.Email}</td>
                  <td className="btn-action">
                    <button onClick={(e) => Delete_User(user)}>
                      <i className="fa-solid fa-trash"></i>
                      <span style={{ marginLeft: "5px" }}>Delete</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
}

export default Dashboard;
