import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import LandingPage from "./components/LandingPage";
import "./components/style.css";
import Dashboard from "./components/Dashboard";
import UserDashboard from "./components/UserDashboard";
import PrivacyPolicy from "./components/PrivacyPolicy";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/UserDashboard" element={<UserDashboard />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
