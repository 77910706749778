import React, { useEffect, useState } from "react";
import dog from "../Image/5100_9_02.png";
import cat from "../Image/8800_2_10.png";
import $ from "jquery";
import axios from "axios";
import { APIURL } from "./apiconfig";
function Subscription(props) {
  const [isdog, setisdog] = useState(true);
  const [arrPackage, setarrPackage] = useState([]);
  const [strmessage, setstrmessage] = useState("");
  useEffect(() => {
    Load_GroominPackage(true);
  }, []);
  const Load_GroominPackage = (pettype) => {
    try {
      axios
        .get(`${APIURL}View_GroomingPackage/0/${pettype ? 1 : 2}`)
        .then((res) => {
          if (res.status === 200) {
            setarrPackage(res.data[0]);
          } else {
            setstrmessage("Error getting Grooming Packgaes");
          }
        })
        .catch((err) => {
          setstrmessage(err.message);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  return (
    <section className="pricing" id="Pricing">
      <span className="sub-absimg1">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg2">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg3">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg4">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg5">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg6">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg7">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg8">
        <i className="fa-solid fa-paw absimg"></i>
      </span>
      <span className="sub-absimg9">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg10">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg11">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg12">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg13">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg14">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg15">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <span className="sub-absimg16">
        <i className="fa-solid fa-paw absimg1"></i>
      </span>
      <h1 className="pricing-title">Grooming Packages</h1>
      <p className="pricing-titletxt">
        Unlock value,Choose Your Plan Empowering Your Jouney With Transparent
        Pricing!
      </p>
      <div className="switchtab">
        <div
          className="tab_btn active"
          onClick={(e) => {
            $(".tab_btn:eq(0)")
              .addClass("active")
              .siblings()
              .removeClass("active");
            setisdog(true);
            Load_GroominPackage(true);
          }}
        >
          <img src={dog} alt="Dog Tab"></img>
        </div>
        <div
          className="tab_btn"
          onClick={(e) => {
            $(".tab_btn:eq(1)")
              .addClass("active")
              .siblings()
              .removeClass("active");
            setisdog(false);
            Load_GroominPackage(false);
          }}
        >
          <img src={cat} alt="Cat Tab"></img>
        </div>
      </div>
      <div className="pricing-card">
        <div className="card-wrapper">
          <h2 className="card-header">Silver</h2>
          <div className="card-detail">
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Bath
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Shampoo
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Deshedding
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Blow Drying
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Nail Clipping
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Ear & Eye Cleaning
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Mouth and Body Splash
            </li>
          </div>
          <h3 className="card-price">
            <i className="bx bx-rupee"></i> 1199<span> / Service</span>
          </h3>
          <button className="card-button" onClick={props.BookNow}>
            Book Now
          </button>
        </div>
        <div className="card-wrapper">
          <h2 className="card-header">Gold</h2>
          <div className="card-detail">
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Bath
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Shampoo
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Deshedding
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Blow Drying
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Nail Clipping
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Ear & Eye Cleaning
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Mouth and Body Splash
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Conditioning
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Combing & Brushing
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Teeth Cleaning & Brushing
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Anal Grand
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Minor Trimming on Face &
              Paws
            </li>
          </div>
          <h3 className="card-price">
            <i className="bx bx-rupee"></i> 1399<span> / Service</span>
          </h3>
          <button className="card-button" onClick={props.BookNow}>
            Book Now
          </button>
        </div>
        <div className="card-wrapper">
          <h2 className="card-header">Diamond</h2>
          <div className="card-detail">
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Bath
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Shampoo
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Deshedding
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Blow Drying
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Nail Clipping
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Ear & Eye Cleaning
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Mouth and Body Splash
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Conditioning
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Combing & Brushing
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Teeth Cleaning & Brushing
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Anal Grand
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Full Body Trimming/Zero
              Haircut
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Paw Manage
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Sanitary Trimming
            </li>
          </div>
          <h3 className="card-price">
            <i className="bx bx-rupee"></i> 1999<span> / Service</span>
          </h3>
          <button className="card-button" onClick={props.BookNow}>
            Book Now
          </button>
        </div>
      </div>
      <div className="pricing-bottomtext">
        <div className="card-wrapper">
          <h2 className="card-header">ADD ON</h2>
          <div className="card-detail">
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Tick and Flea Treatment
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Tick Removal
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Premium Tick and Flea
              Free Shampoo bath
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Medicine Depends upon the
              Pets Weight
            </li>
            <li>
              <i className="fa-solid fa-paw"></i>&nbsp;Paw massage
            </li>
          </div>
          <h3 className="card-price">
            <i className="bx bx-rupee"></i> 600<span> / Service</span>
          </h3>
        </div>
      </div>
      <div className="offer-content">
        <h4>Opening Offer 16th July to 18 Aug</h4>
        <div className="offer-pack">
          <div className="pack-cont">
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Bath
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Nail Clipping
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Paw Trim
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Ear Cleaning
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Blow Dry
            </span>
          </div>
          <div className="pack-cont">
            <span className="ht">15 KG Below</span>
            <span className="amt">
              <span>
                <sub>Rs.</sub> <span className="st">1299/-</span>
              </span>
              <span>
                <sub>Rs.</sub>699 /-
              </span>
            </span>
            <span className="ht">15 KG Above</span>
            <span className="amt">
              <span>
                <sub>Rs.</sub>
                <span className="st">1699 /-</span>
              </span>
              <span>
                <sub>Rs.</sub>999 /-
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="offer-content">
        <h4>6 Month Offer For Both Dogs & Cat</h4>
        <div className="offer-pack">
          <div className="pack-cont">
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Bath
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Shampoo
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Blow Drying
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Nail Clipping
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Ear & Eye Cleaning
            </span>
            <span>
              <i className="fa-solid fa-paw"></i>&nbsp;Mouth and Body Splash
            </span>
            <div className="offer-content"></div>
          </div>
          <div className="pack-cont">
            <span className="ht">15 KG Below</span>
            <span className="amt">
              <span>
                <sub>Rs.</sub> <span className="st">7800 /-</span>
              </span>
              <span>
                <sub>Rs.</sub>5999 /-
              </span>
            </span>
            <span className="ht">15 KG Above</span>
            <span className="amt">
              <span>
                <sub>Rs.</sub>
                <span className="st">10200 /-</span>
              </span>
              <span>
                <sub>Rs.</sub>7999 /-
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscription;
